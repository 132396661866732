<template>
  <div class="form">
    <div
        v-if="bookingNoticeId && bookingDetails"
        class="booking-details">
      <h5>Submit your Booking</h5>
      <p>Site: <strong>{{ bookingDetails.bookingDetails.site.title }}</strong></p>
      <p>Time Slot: <strong>{{ bookingDetails.endTime | formatTime }} &ndash; {{ bookingDetails.startTime | formatTime }}</strong></p>
    </div>
    <CoreFormBuilderV2
        :data="bookingPageData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { BookingPage } from "@/Modules/OdysseyModels"
  import formConfig from "./formConfig"
  import moment from "moment/moment";

  export default {
    name: "BookingSlotForm",
    filters: {
      formatTime(data) {
        return moment(data).format('H:mma')
      },
      formatDate(date) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    props: {
      dialogProps: { type: Object, required: true },
      bookingPageData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        formConfig
      }
    },
    computed: {
      mode() {
        return this.dialogProps.mode
      },
      bookingDetails() {
        if (this.dialogProps?.optionsParams) {
          return this.dialogProps.optionsParams
        }
        return null
      },
      bookingNoticeId() {
        if (this.$route.params.id) {
          return this.$route.params.id
        }
        return null
      }
    },
    created() {
      if (!this.dialogProps.optionsParams) {
        this.onCancel();
      }
    },
    methods: {
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()
          if (this.bookingNoticeId) {
            flatFormData.bookingNoticeId = this.bookingNoticeId
          }else {
            flatFormData.bookingNoticeId = this.bookingDetails.bookingNoticeId
          }
          flatFormData.timeStart = this.bookingDetails.startTime
          flatFormData.timeEnd = this.bookingDetails.endTime

          if (this.mode === "create") {
            await BookingPage.api().create(flatFormData)
          } else if (this.mode === "edit") {
            flatFormData.id = this.bookingPageData.id

            await BookingPage.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your Booking Details have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.booking-details {
  background: #f1f3fa;
  padding: var(--padding-s) var(--padding-l);
}
</style>