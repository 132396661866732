<template>
  <div class="form-builder">
    <el-form
        :ref="formName"
        v-loading="isLoading"
        label-width="120px"
        :model="formData"
        label-position="top">
      <template v-for="(formgroup, indexFormGroup) in formData">
        <div
            v-if="checkGroupDependency(formgroup._options)"
            :key="`formgroup-${indexFormGroup}`"
            class="form-group-container">
          <h3
              v-show="
                formgroup._options ? !formgroup._options.hideGroupTitle : true
              ">
            <span>
              {{ indexFormGroup | camelToWords }}
            </span>
            <span v-if="formgroup._options && formgroup._options.editToggle">
              <i
                  class="el-icon-edit-outline edit-icon"
                  @click="editToggle(formgroup._options.editToggle)" />
            </span>
            <el-tooltip
                v-if="formgroup._options && formgroup._options.hintText"
                popper-class="hint-tooltip"
                :open-delay="450">
              <i class="el-icon-info hint" />

              <div
                  slot="content"
                  class="hint-tooltip">
                {{ formgroup._options.hintText }}
              </div>
            </el-tooltip>
          </h3>

          <el-row :gutter="10">
            <template v-for="(formItem, index2) in formgroup">
              <el-col
                  v-show="formItem.type || formItem.Component"
                  v-if="
                    index2 !== '_options' && formItem.dependency
                      ? checkDependency(formItem.dependency, indexFormGroup)
                      : true
                  "
                  :key="`formItem-${index2}`"
                  :span="setRowSpan(formgroup._options)"
                  :gutter="0"
                  :data-testid="`form-${formName}-col-${index2}`">
                <transition name="fade">
                  <template v-if="formItem.Component">
                    <div>
                      <label
                          :for="index2"
                          class="el-form-item__label">
                        {{ formItem.label }}
                        <el-tooltip
                            v-if="formItem.hintText"
                            popper-class="hint-tooltip"
                            :open-delay="450">
                          <i class="el-icon-info hint" />
                          <div
                              slot="content"
                              class="hint-tooltip">
                            {{ formItem.hintText }}
                          </div>
                        </el-tooltip>
                      </label>
                      <component
                          :is="formItem.Component"
                          v-model="formItem.value"
                          :form-meta="formMeta"
                          :label="formItem.label"
                          :value="formItem.value"
                          v-bind="getFormItemProps(formItem.props)"
                          @input="
                            (value) =>
                              setValue(
                                value,
                                `${[indexFormGroup]}.${[index2]}.value`
                              )
                          " />
                    </div>
                  </template>

                  <el-form-item
                      v-else
                      :label="formItem.label"
                      :rules="formItem.validation"
                      :prop="`${[indexFormGroup]}.${[index2]}.value`">
                    <template v-if="formItem.type === 'placeholder'">
                      <div class="placeholder" />
                    </template>
                    <!-- Text Input Field -->
                    <template
                        v-else-if="
                          formItem.type === 'textarea' ||
                            formItem.type === 'text' ||
                            formItem.type === 'email' ||
                            formItem.type === 'tel' ||
                            formItem.type === 'number'
                        ">
                      <el-input
                          v-model="formItem.value"
                          :type="formItem.type"
                          :disabled="
                            isLoading ||
                              formItem.disabled ||
                              (formgroup._options && formgroup._options.disabled)
                          "
                          :placeholder="formItem.placeholder"
                          :clearable="formItem.clearable"
                          :data-testid="`form-${formName}-input-${index2}`" />
                    </template>

                    <!-- Password Input Field -->
                    <template v-else-if="formItem.type === 'password'">
                      <el-input
                          v-model="formItem.value"
                          :disabled="
                            isLoading ||
                              formItem.disabled ||
                              (formgroup._options && formgroup._options.disabled)
                          "
                          :placeholder="formItem.placeholder"
                          :clearable="formItem.clearable"
                          :data-testid="`form-${formName}-input-${index2}`"
                          show-password />
                    </template>

                    <!-- Radio Button -->
                    <template v-else-if="formItem.type === 'radio'">
                      <el-radio-group v-model="formItem.value">
                        <template v-for="(option, index) in formItem.options">
                          <el-radio
                              :key="`radio-option-${index}`"
                              :label="option.value">
                            {{ option.label }}
                          </el-radio>
                        </template>
                      </el-radio-group>
                    </template>

                    <!-- Slider and Range Slider -->
                    <template v-else-if="formItem.type === 'slider'">
                      <el-slider
                          v-model="formItem.value"
                          :range="formItem.range"
                          :marks="formItem.marks"
                          :min="formItem.min"
                          :max="formItem.max"
                          :show-stops="formItem.showStops"
                          :step="formItem.step"
                          :format-tooltip="formItem.formatTooltip" />
                    </template>

                    <!-- Single Date Input Field -->
                    <template v-else-if="formItem.type === 'time'">
                      <el-time-picker
                          v-model="formItem.value"
                          :picker-options="
                            formItem.format
                              ? { format: formItem.format, ...formItem.options }
                              : formItem.options
                          "
                          :format="formItem.format"
                          :disabled="
                            isLoading ||
                              formItem.disabled ||
                              (formgroup._options && formgroup._options.disabled)
                          "
                          :placeholder="formItem.placeholder"
                          :data-testid="`form-${formName}-input-${index2}`" />
                    </template>

                    <template v-else-if="formItem.type === 'timeSelect'">
                      <el-time-select
                          v-model="formItem.value"
                          :picker-options="getFormOptions(formItem)"
                          :clearable="formItem.clearable"
                          :disabled="
                            isLoading ||
                              formItem.disabled ||
                              (formgroup._options && formgroup._options.disabled)
                          "
                          :placeholder="formItem.placeholder"
                          :data-testid="`form-${formName}-input-${index2}`" />
                    </template>

                    <!-- Single Date Input Field -->
                    <template v-else-if="formItem.type === 'date'">
                      <el-date-picker
                          v-model="formItem.value"
                          type="date"
                          :disabled="
                            isLoading ||
                              formItem.disabled ||
                              (formgroup._options && formgroup._options.disabled)
                          "
                          :placeholder="formItem.placeholder"
                          :picker-options="formItem.options"
                          :data-testid="`form-${formName}-input-${index2}`" />
                    </template>

                    <!-- Single Date Time Input Field -->
                    <template v-else-if="formItem.type === 'dateTime'">
                      <el-date-picker
                          v-model="formItem.value"
                          type="datetime"
                          :disabled="
                            isLoading ||
                              formItem.disabled ||
                              (formgroup._options && formgroup._options.disabled)
                          "
                          :placeholder="formItem.placeholder"
                          :picker-options="formItem.options"
                          :data-testid="`form-${formName}-input-${index2}`" />
                    </template>

                    <!-- Single DateRange Input Field -->
                    <template v-else-if="formItem.type === 'dateRange'">
                      <el-date-picker
                          v-model="formItem.value"
                          type="daterange"
                          range-separator="To"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          :picker-options="formItem.options"
                          :disabled="
                            isLoading ||
                              formItem.disabled ||
                              (formgroup._options && formgroup._options.disabled)
                          "
                          :placeholder="formItem.placeholder"
                          :data-testid="`form-${formName}-input-${index2}`" />
                    </template>

                    <!-- Color Picker Input Field -->
                    <template v-else-if="formItem.type === 'color'">
                      <el-color-picker
                          v-model="formItem.value"
                          :color-format="formItem.colorFormat"
                          :predefine="formItem.predefinedColors"
                          :data-testid="`form-${formName}-input-${index2}`" />
                    </template>

                    <template v-else-if="formItem.type === 'switch'">
                      <el-switch
                          v-model="formItem.value"
                          active-color="#35c58b"
                          inactive-color="#ddd"
                          :data-testid="`form-${formName}-input-${index2}`"
                          :disabled="
                            isLoading ||
                              formItem.disabled ||
                              (formgroup._options && formgroup._options.disabled)
                          " />
                    </template>

                    <template v-else-if="formItem.type === 'select'">
                      <el-select
                          v-model="formItem.value"
                          :filterable="formItem.filterable || false"
                          :multiple="formItem.multiple || false"
                          class="dpl-block"
                          :placeholder="formItem.placeholder"
                          :data-testid="`form-${formName}-input-${index2}`">
                        <el-option
                            v-for="item in formItem.options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                          <span style="float: left">{{ item.label }}</span>
                          <span
                              style="float: right; color: #8492a6; font-size: 0.8em">{{ item.value }}</span>
                        </el-option>
                      </el-select>
                    </template>

                    <template v-else-if="formItem.type === 'entitySelect'">
                      <EntitySelect
                          :form-item="formItem"
                          :form-name="formName"
                          :index2="index2" />
                    </template>

                    <template v-else-if="formItem.type === 'selectOptions'">
                      <SelectOptionsPair v-model="formItem.value" />
                    </template>

                    <template v-else-if="formItem.type === 'image-upload'">
                      <el-upload
                          class="image-uploader"
                          :action="formItem.uploadAction"
                          :show-file-list="false"
                          :on-success="formItem.uploadSuccess"
                          :before-upload="formItem.beforeUpload"
                          :on-preview="formItem.handlePreview">
                        <img
                            v-if="formItem.imageUrl"
                            :src="formItem.imageUrl"
                            class="image">
                        <i
                            v-else
                            class="el-icon-plus image-uploader-icon" />
                        Upload Image
                      </el-upload>
                    </template>

                    <div
                        v-if="formItem.help"
                        class="system-message-container info">
                      {{ formItem.help }}
                    </div>
                  </el-form-item>
                </transition>
              </el-col>
            </template>
          </el-row>
        </div>
      </template>
      <transition name="fade">
        <el-alert
            v-if="formError"
            title="Please fill out all required fields."
            type="error"
            effect="dark" />
      </transition>

      <footer>
        <FormFooterOptions>
          <el-form-item v-if="deleteButtonVisible">
            <el-button
                :disabled="isSubmitting"
                class="button btn-delete"
                @click="onDelete(formName)">
              Delete
            </el-button>
          </el-form-item>

          <div class="button-bar-group">
            <el-form-item v-if="cancelButtonVisible">
              <el-button
                  :disabled="isSubmitting"
                  class="button btn-cancel"
                  @click="onCancel(formName)">
                Cancel
              </el-button>
            </el-form-item>

            <transition name="fade">
              <el-form-item v-if="formUpdated && resetButtonVisible">
                <el-button
                    :disabled="isSubmitting"
                    class="button btn-cancel"
                    @click="onReset(formName)">
                  Reset
                </el-button>
              </el-form-item>
            </transition>

            <transition name="fade">
              <el-form-item v-if="formUpdated && submitButtonVisible">
                <el-button
                    :disabled="isSubmitting"
                    type="success"
                    class="button btn-confirm"
                    @click="onSubmit(formName)">
                  Submit
                </el-button>
              </el-form-item>
            </transition>
          </div>
        </FormFooterOptions>
      </footer>
    </el-form>
  </div>
</template>

<script>
import FormFooterOptions from "./FormFooterOptions"
import SelectOptionsPair from "./SelectOptionsPair"
import isFunction from "lodash/isFunction"
import get from "lodash/get"
import set from "lodash/set"
import EntitySelect from "./custom-ui/EntitySelect"

export default {
  name: "CoreFormBuilderElementUIHandler",
  components: {
    FormFooterOptions,
    SelectOptionsPair,
    EntitySelect
  },
  filters: {
    camelToWords(string) {
      const result = string.replace(/([A-Z])/g, " $1")

      return result.charAt(0).toUpperCase() + result.slice(1)
    }
  },
  props: {
    formMeta: { type: Object, default: () => ({}) },
    value: { type: Object, required: true },
    isLoading: { type: Boolean, default: false },
    formName: { type: String, default: "defaultForm" }
  },
  data() {
    return {
      isSubmitting: false,
      formUpdated: false,
      formError: false,
      selectValue: [],
      selectLabel: [],
      selectOptionIndex: 0
    }
  },
  computed: {
    formData: {
      get() {
        return this.value
      },
      set() {}
    },
    deleteButtonVisible() {
      return this.$listeners.onDelete
    },
    cancelButtonVisible() {
      return this.$listeners.onCancel
    },
    resetButtonVisible() {
      return this.$listeners.onReset || this.$listeners.cancelForm
    },
    submitButtonVisible() {
      return this.$listeners.onSubmit || this.$listeners.submitForm
    }
  },
  watch: {
    formData: {
      handler(val) {
        if (val && !this.initialising) {
          this.formUpdated = true
        }
      },
      deep: true
    }
  },

  methods: {
    setValue(value, formKey) {
      this.formData = set(this.formData, formKey, value)
    },
    editToggle(func) {
      func(this)
    },
    setRowSpan(formGroupOptions = {}) {
      const { rowSpan = 12 } = formGroupOptions

      if (rowSpan) {
        return rowSpan
      }
    },
    // formGroup.dependency: { group, field, val}
    checkGroupDependency(formGroupOptions = {}) {
      const { dependency } = formGroupOptions
      if (dependency) {
        const fieldPath = `${dependency.group}.${dependency.field}`
        const groupExists = get(this.formData, dependency.group)
        const fieldExists = get(this.formData, fieldPath)

        if (groupExists) {
          if (fieldExists) {
            const fieldValue = get(this.formData, fieldPath).value

            return dependency.value === "*" ? fieldValue : dependency.value === fieldValue
          } else {
            //eslint-disable-next-line
            console.warn("FormGroup Group Field does not exist", dependency.field)
          }
        } else {
          //eslint-disable-next-line
          console.warn("FormGroup Group does not exist", dependency.group)
        }
      }
      return true
    },
    checkDependency(value, key) {
      if (typeof value === "object") {
        const fieldValue = get(this.formData, `${key}.${value.field}.value`)
        // Field can have any value to trigger the dependent child
        if (value.value === "*") {
          return fieldValue != null
        }
        if (this.formData[key]) {
          return fieldValue === value.value
        } else {
          //eslint-disable-next-line
          console.warn("missing dependency field", value.field)
          return false
        }
      } else {
        return get(this.formData, `${key}.${value}.value`)
      }
    },
    setSubmitting(isSubmitting) {
      this.isSubmitting = isSubmitting
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.formError = false
          this.setSubmitting(true)
          this.$emit("submitForm", this)
          this.$emit("onSubmit", this)
        } else {
          this.formError = true
          return false
        }
      })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.$emit("cancelForm", this)
      this.$emit("onReset", this)
    },
    onCancel() {
      this.$emit("onCancel", this)
    },
    onDelete() {
      this.$emit("onDelete", this)
    },
    getFormOptions(formItem) {
      return isFunction(formItem.options) ? formItem.options(this) : formItem.options
    },
    getFlatData() {
      const flatFormData = {}
      const blacklistFieldNames = ["placeholder", "_options"]
      // Go through each field and only select the value-field to pair with the key
      Object.keys(this.formData).filter(formGroup => {
        Object.keys(this.formData[formGroup]).filter(fieldName => {
          if (!blacklistFieldNames.includes(fieldName)) {
            const value = get(this.formData, `${formGroup}.${fieldName}.value`, null)

            set(flatFormData, fieldName.replace("/", "."), value)
          }
        })
      })

      return flatFormData
    },
    getFormItemProps(props) {
      if (isFunction(props)) {
        return props(this)
      } else {
        return props
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep  .el-form--label-top {
  .el-form-item__label {
    margin: 0;
    padding: 0;
  }
}
::v-deep  .options-panel {
  margin: 4em -2em 0 -2em;
}

::v-deep  .el-input__icon,
::v-deep  .el-date-editor .el-range__icon {
  color: #787879;
}

::v-deep  .el-date-editor .el-range-separator {
  min-width: 2em;
}

::v-deep  .el-form-item__label {
  font-weight: bold;
  line-height: 1.6;
}

footer {
  margin-top: 3em;
}
.dpl-block {
  display: block;
}
.placeholder {
  width: 100%;
  height: 6em;
}
.image-uploader {
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px dashed var(--main-border-color);
  transition: var(--main-transition);
}
.image-uploader:hover {
  border-color: var(--main-darker-grey);
  background: var(--main-light-blue);
}
.image-uploader-icon {
  font-size: 1em;
  color: #8c939d;
  width: 1em;
  height: 1em;
  margin: 0 1em;
  line-height: 1em;
  text-align: center;
}
.image {
  width: 1em;
  height: 1em;
  display: block;
}

.hint {
  cursor: help;
}

.hint-tooltip {
  max-width: 500px !important;
}

.edit-icon {
  cursor: pointer;
}
</style>
