export default {
  fields: {
    bookingDetails: {
      name: {
        type: "text",
        value: null,
        label: "Full Name",
        props: {
          placeholder: "ie. John Doe",
        },
        validation: [{required: true, message: "Please enter your full name", trigger: "blur"}]
      },
      email: {
        type: "email",
        value: null,
        label: "Email",
        props: {
          placeholder: "ie. john.doe@example.org",
        },
        validation: [{required: true, message: "Please enter your email", trigger: "blur"}]
      },
      phoneNumber: {
        type: "text",
        value: null,
        label: "Phone Number",
        props: {
          placeholder: "ie. +61 4 1234 5678",
        },
        validation: [{required: true, message: "Please enter your Phone Number", trigger: "blur"}]
      },
      unitNumber: {
        type: "text",
        value: null,
        label: "Unit Number",
        props: {
          placeholder: "ie. 100",
        },
        validation: [{required: true, message: "Please enter your Unit Number", trigger: "blur"}]
      },
      comments: {
       width: 24,
        type: "textarea",
        value: null,
        label: "Any comments?",
      }
    }
  }
}
