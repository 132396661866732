import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    active: 1,
    customerId: null,
    customerFirstName: null,
    customerLastName: null,
    customerPhone: null,
    priceCost: null,
    pricePaid: null,
    paymentType: null,
    status: null,
    notes: null,
    createdAt: null,
    updatedAt: null,
    orderID: null,
    paypalOrderID: null,
    username: null,
    email: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition