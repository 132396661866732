<template>
  <div
      class="notifications-toast"
      :class="type"
      :data-test-id="dataTestId">
    <template v-if="!decay">
      <button
          class="close"
          @click="close()">
        X
      </button>
    </template>
    <div class="notification-inner">
      <div
          class="icon-container"
          :class="type">
        <template v-if="type === 'version-update'">
          <VersionUpdateIcon color="#47b8ff" />
        </template>
        <template v-else>
          <span :class="notificationIcon" />
        </template>
      </div>
      <div class="text-container">
        <core-text tag="h3">
          {{ title }}
        </core-text>
        <template v-if="type === 'version-update'">
          <core-text
              class="message-container"
              :class="messageContainerType">
            A new update is available.
            Click <strong>Update</strong> to reload this page or
            close this message and we'll remind you later.
          </core-text>
          <div class="update-button-container">
            <el-button
                type="success"
                size="mini"
                @click="updateVersion()">
              Update Now
            </el-button>
          </div>
        </template>
        <template v-else>
          <template v-for="msg in messages">
            <core-text
                :key="msg"
                class="message-container"
                :class="messageContainerType"
                v-html="msg" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import VersionUpdateIcon from "./components/VersionUpdateIcon"
  import isArray from "lodash/isArray"
  import kebabCase from "lodash/kebabCase"

  export default {
    name: "CoreNotificationsToast",
    components: {
      VersionUpdateIcon
    },
    key: {
      type: Number,
      required: true
    },
    props: {
      title: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      message: {
        type: [String, Array],
        default: ""
      },
      decay: {
        type: [Boolean, Number],
        default: 3500
      },
      payload: {
        type: [Object, Function],
        required: false
      }
    },
    computed: {
      dataTestId() {
        return `toast-notification-${kebabCase(this.title)}`
      },
      messages() {
        return isArray(this.message) ? this.message : [this.message]
      },
      messageSize() {
        return this.message.length
      },
      messageContainerType() {
        return this.messageSize > 300 ? "code" : ""
      },
      notificationIcon() {
        switch (this.type) {
          case "success":
            return "el-icon-check"
          case "error":
          case "warning":
            return "el-icon-warning-outline"
          case "info":
            return "el-icon-info"
          default:
            return "el-icon-info"
        }
      }
    },
    mounted() {
      if (this.decay) {
        setTimeout(() => {
          this.close()
        }, this.decay)
      }
    },
    methods: {
      updateVersion() {
        localStorage.setItem("git-version", this.payload.version)
        location.reload()
      },
      close() {
        this.$store.dispatch("core/notifications/removeFromNotifications", this.key)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .notifications-toast {
    background: #fff;
    box-shadow: var(--box-shadow-container);
    border-radius: 0.25em;
    padding: 1em;
    width: 24em;
    display: block;
    bottom: 1em;
    right: 1em;
    word-break: break-word;
    border-left: 4px solid;
    position: relative;
    margin-bottom: 1em;

    &.version-update {
      width: 34em;
    }

    h3 {
      margin: 0;
    }

    &.version-update {
      border-left-color: #47b8ff;
    }
    &.success {
      border-left-color: var(--main-green);
    }
    &.warning {
      border-left-color: var(--main-orange);
    }
    &.error {
      border-left-color: var(--main-red);
    }
    &.info {
      border-left-color: #47b8ff;
    }

    button {
      &.close {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: 2em;
        position: absolute;
        right: -0.5em;
        top: -0.5em;
        border: 0;
        transition: var(--main-transition);
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
        cursor: pointer;

        &:hover {
          background: #000;
          color: white;
        }
      }
    }

    .notification-inner {
      display: flex;
      align-items: center;

      .icon-container {
        flex: 1;
        min-width: 2em;
        font-size: 1.6em;
        margin-right: 1em;
        border-radius: 50%;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.1);

        &.version-update {
          min-width: 5em;
          height: 5em;
          background: transparent;
        }

        &.success {
          color: var(--main-green);
          background: #dffff1;
        }
        &.warning {
          color: var(--main-orange);
          background: #ffe4bf;
        }
        &.error {
          color: var(--main-red);
          background: #ffdcdc;
        }
        &.info {
          color: #47b8ff;
          background: #e4f5ff;
        }
      }

      .text-container {
        .update-button-container {
          padding-top: 1em;

          ::v-deep  .el-button--success {
            box-shadow: var(--box-shadow-button);
          }
        }

        .message-container {
          &.code {
            background: #fffae1;
            padding: 1em;
            max-height: 14em;
            overflow-y: auto;
            border-radius: 0.5em;
            white-space: pre-wrap;
            line-height: 1.4;
          }
        }
      }
    }
  }
</style>
