import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    active: 1,
    title: null,
    description: null,
    selection: null,
    noticeType: null,
    timeBlocks: null,
    createdAt: null,
    updatedAt: null,
    smokeAlarmRepairs: null,
    fireDoorRepairs: null,
    unitInspections: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition