<template>
  <el-dropdown
      trigger="click"
      placement="bottom-start"
      @command="selectBreadcrumbOption">
    <core-text
        clickable
        class="el-dropdown-link">
      <span>
        {{ selectedOptionLabel }}
      </span><i class="el-icon-arrow-down el-icon--right" />
    </core-text>

    <el-dropdown-menu
        slot="dropdown"
        placement="bottom-start">
      <div
          v-if="searchable"
          class="breadcrumb-dropdown__search-input">
        <el-input
            v-model="searchValue"
            size="mini"
            placeholder="search" />
      </div>

      <el-divider v-if="searchable" />

      <div
          v-for="(option, index) in selectableBreadcrumbOptions"
          :key="index">
        <slot
            name="breadcrumb-dropdown-item"
            :option="option">
          <el-dropdown-item
              :command="option"
              :class="{ selected: option.value === selectedOptionValue }">
            <core-text>
              {{ option.label }}
            </core-text>
          </el-dropdown-item>
        </slot>
      </div>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  props: {
    options: { type: Array, default: () => [] },
    searchable: { type: Boolean, default: false },
    initialValue: { type: Object, default: null },
    onOptionSelect: { type: Function, default: () => null },
    resetOnOptionsChange: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    loadingText: { type: String, default: "string" }
  },
  data() {
    return { selectedOption: null, searchValue: "" }
  },
  computed: {
    selectableBreadcrumbOptions() {
      if (this.searchable) {
        return this.options.filter(({ label }) =>
          `${label}`.toLowerCase().includes(this.searchValue.toLowerCase())
        )
      } else {
        return this.options
      }
    },
    cachedOptions() {
      return this.options
    },
    selectedOptionValue() {
      if (this.loading) return null
      return this.selectedOption.value
    },
    selectedOptionLabel() {
      if (this.loading) return this.loadingText

      return this.selectedOption ? this.selectedOption.label : ""
    }
  },
  watch: {
    cachedOptions() {
      if (this.resetOnOptionsChange) {
        this.selectBreadcrumbOption(this.cachedOptions[0])
      }
    },
    loading: {
      immediate: true,
      handler() {
        if (!this.loading) {
          this.selectedOption = this.initialValue ? this.initialValue : this.options[0] || null
          this.onOptionSelect(this.selectedOption)
        }
      }
    }
  },

  methods: {
    selectBreadcrumbOption(selectedOption) {
      this.selectedOption = selectedOption
      this.onOptionSelect(selectedOption)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}
.breadcrumb-dropdown__search-input {
  padding: 0 20px;
}
::v-deep  .el-divider--horizontal {
  margin: 10px 0;
}
.selected {
  background-color: #ecf5ff;
  color: #66b1ff;
}
</style>
