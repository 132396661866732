<template>
  <div class="dashboard-base">
    <component :is="appName" />
  </div>
</template>

<script>
import TheStylex from "@/Modules/Dashboard/views/TheStylex"
import Otocare from "@/Modules/Dashboard/views/Otocare"
export default {
  name: "DashboardBase",
  components: {
    TheStylex,
    Otocare
  },
  data() {
    return {
      appName: process.env.VUE_APP_BASE_PATH
    }
  }
}
</script>