<template>
  <div
      class="label-and-text"
      :class="{ 'loading': loading }">
    <core-text
        tag="h4"
        bold
        label
        capitalize
        class="header">
      {{ label }}
    </core-text>

    <router-link
        v-if="link"
        :to="link"
        class="link ghost-element-loading">
      <core-text
          tag="h4"
          truncate>
        {{ text }}
      </core-text>
    </router-link>

    <core-text
        v-else
        truncate
        tag="h4"
        class="text ghost-element-loading">
      {{ text }}
    </core-text>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "" },
    link: { type: Object, default: null },
    text: { type: String, default: "" },
    loading: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.label-and-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  ::v-deep  .core-text {
    &.header {
      width: 45%;
    }
  }

  .link {
    cursor: pointer;
    color: #409eff;
    text-decoration: none;
  }
}
</style>
