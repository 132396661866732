<template>
  <fragment>
    <CoreScreen
        card
        class="bookingPages-wrapper"
        header="Book Inspection"
        icon="BookingPagesIcon">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
export default {
  name: "BookingPages",
}
</script>
