<template>
  <div>
    <div @click="dialogVisible = true">
      <slot />
    </div>

    <core-dialog
        v-if="dialogVisible"
        :dialog-props="{closeDialog:onClose}"
        title=" Are you absolutely sure?"
        :append-to-body="true"
        :visible="dialogVisible"
        width="400px"
        class="dialog"
        :before-close="onClose">
      <div
          slot="content"
          v-loading="loading">
        <div class="description">
          <core-text
              v-if="showConfirmPrompt"
              id="description"
              bold>
            {{ title }}
          </core-text>

          <el-alert
              v-if="showSuccessPrompt"
              type="success"
              :closable="false">
            <core-text
                slot="title"
                bold>
              <i class="el-alert__icon el-icon-success" />{{ successCount }} out of {{ selectedCount }} items successful
            </core-text>
          </el-alert>

          <template v-if="showErrors">
            <el-alert
                type="success"
                :closable="false">
              <core-text
                  slot="title"
                  bold>
                <i class="el-alert__icon el-icon-success" />{{ successCount }} out of {{ selectedCount }} items successful
              </core-text>
            </el-alert>
            <el-alert
                v-for="(error, index) in errors"
                :key="index"
                type="error"
                :closable="false">
              <div
                  slot="title"
                  class="error-alert flex-column">
                <core-text
                    bold
                    class="flex items-center">
                  <i class="el-alert__icon el-icon-error" />{{ getErrorTitle(error) }}
                </core-text>

                <div class="description-box">
                  <core-text v-if="expandedErrorIndex === index">
                    {{ getErrorDescription(error) }}
                  </core-text>

                  <core-text
                  
                      clickable
                      semibold
                      @click="expandError(index)">
                    {{ getExpandingText(index) }}
                  </core-text>
                </div>
              </div>
            </el-alert>
          </template>
        </div>

        <div
            v-if="showConfirmUUID"
            class="confirm-input">
          <label>
            <core-text
                label
                semibold>
              Please type "{{ uuid }}" to confirm.
            </core-text>
            <el-input
                v-model="confirmUUID"
                size="small" />
          </label>
        </div>

        <div class="buttons flex justify-end">
          <template v-for="button of buttons">
            <el-button
                v-if="!button.hidden"
                :key="button.label"
                v-a11y:button="button.ariaLabel"
                size="small"
                class="capitalize"
                :type="button.type"
                :disabled="button.disabled"
                @click="button.onClick">
              {{ button.label }}
            </el-button>
          </template>
        </div>
      </div>
    </core-dialog>
  </div>
</template>

<script>
import { uuid as uuidv4 } from "uuidv4"

export default {
  props: {
    bulkAction: { type: Object, required: true },
    selectedItems: { type: Array, default: () => [] }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      success: false,
      expandedErrorIndex: null,
      errors: [],
      originalSelectedItems: [],
      confirmUUID: "",
      uuid: uuidv4().split("-")[0]
    }
  },
  computed: {
    buttons() {
      const {
        onClose,
        onBulkActionConfirm,
        onTryAgainClick,
        confirmProps,
        success,
        errors,
        loading,
        uuid,
        confirmUUID
      } = this

      return [
        {
          onClick: onClose,
          label: confirmProps.cancelButtonText,
          hidden: success,
          ariaLabel: "cancel and close dialog",
          disabled: loading
        },
        {
          onClick: onClose,
          label: "close",
          hidden: !success,
          disabled: loading
        },
        {
          type: "primary",
          hidden: errors.length || success,
          onClick: onBulkActionConfirm,
          label: confirmProps.confirmButtonText,
          ariaLabel: "perform bulk action",
          disabled: loading || uuid !== confirmUUID
        },
        {
          type: "primary",
          hidden: !errors.length || success,
          onClick: onTryAgainClick,
          label: confirmProps.tryAgainButtonText,
          ariaLabel: "try bulk action again",
          disabled: loading
        }
      ]
    },
    confirmProps() {
      const { bulkAction, selectedCount, errorCount, getItemTextPluralization } = this
      const { confirmProps = {} } = bulkAction

      const defaultConfirmProps = {
        title: `Are you sure you want to ${bulkAction.label.toLowerCase()} ${selectedCount} ${getItemTextPluralization()}?`,
        loadingText: "Performing bulk action",
        icon: "el-icon-warning",
        iconColor: "red",
        confirmButtonText: "OK",
        tryAgainButtonText: `try again for ${errorCount} ${getItemTextPluralization(errorCount)}`,
        cancelButtonText: "Cancel"
      }

      return { ...defaultConfirmProps, ...confirmProps }
    },
    showConfirmUUID() {
      const { loading, errors, success } = this

      return !loading && !errors.length && !success
    },
    showConfirmPrompt() {
      const { loading, errors, success } = this

      return !loading && !errors.length && !success
    },
    showSuccessPrompt() {
      const { loading, errors, success } = this

      return !loading && !errors.length && success
    },
    showErrors() {
      const { loading, errors } = this

      return errors.length && !loading
    },
    title() {
      const { confirmProps, selectedCount } = this

      return confirmProps.title.replace("{{n}}", selectedCount)
    },
    loadingText() {
      const { confirmProps, selectedCount } = this

      return confirmProps.loadingText.replace("{{n}}", selectedCount)
    },
    selectedCount() {
      return this.originalSelectedItems.length
    },
    errorCount() {
      return this.errors.length
    },
    successCount() {
      return this.selectedCount - this.errorCount
    }
  },
  watch: {
    selectedItems() {
      if (!this.dialogVisible) {
        this.originalSelectedItems = this.selectedItems
      }
    }
  },
  methods: {
    setErrorLoading(index, loading) {
      this.errors = this.errors.map((error, errorIndex) => {
        if (index === errorIndex) {
          return { ...error, loading }
        }
        return error
      })
    },
    async handleTryAgainClick(index) {
      this.setErrorLoading(index, true)

      await this.errors[index].tryAgain()

      this.setErrorLoading(index, false)
    },
    getItemTextPluralization(count = this.selectedCount) {
      return count > 1 ? "items" : "item"
    },
    getExpandingText(index) {
      return this.expandedErrorIndex !== index ? "More..." : "Less..."
    },
    getErrorTitle(error) {
      return error.title
    },
    getErrorDescription(error) {
      return error.description
    },
    expandError(index) {
      if (this.expandedErrorIndex === index) {
        this.expandedErrorIndex = null
      } else {
        this.expandedErrorIndex = index
      }
    },
    onClose() {
      if (!this.loading) {
        this.dialogVisible = false
        this.success = false
      }
    },
    handleSuccess() {
      this.errors = []
      this.loading = false
      this.success = true
    },
    async onBulkActionConfirm() {
      try {
        const { bulkAction, originalSelectedItems } = this

        this.loading = true

        await bulkAction.onConfirm(originalSelectedItems)

        this.handleSuccess()
      } catch (errors) {
        this.errors = errors
        this.loading = false
      }
    },
    async onTryAgainClick() {
      try {
        const { bulkAction } = this

        this.loading = true

        const errorItems = this.errors.map(({ item }) => item)

        await bulkAction.onConfirm(errorItems)

        this.handleSuccess()
      } catch (errors) {
        this.errors = errors
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: var(--padding-xl);

  i {
    font-size: 1.2em;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.6);

    &:hover {
      color: rgba(0, 0, 0, 1);
    }
  }
}

.confirm-input {
  padding-bottom: var(--padding-l);
  .core-text {
    padding-bottom: var(--padding-s);
  }
}

::v-deep  .el-dialog__body {
  margin-top: 0;
  padding: var(--padding-m) var(--padding-l);
  padding-top: var(--padding-xs);
}

.el-alert {
  margin-bottom: var(--margin-s);

  ::v-deep  .el-alert__content {
    padding-left: 0;
    width: 100%;
  }

  ::v-deep  .el-alert__icon {
    margin-right: var(--margin-xs);
  }
}

.error-alert {
  .description-box {
    padding-left: var(--padding-l);
  }
}

.dialog {
  min-width: 400px;
}

.description {
  padding-bottom: var(--padding-xl);
  padding-top: var(--padding-m);
}
</style>