import { render, staticRenderFns } from "./BookingSlotForm.vue?vue&type=template&id=296d38ec&scoped=true&"
import script from "./BookingSlotForm.vue?vue&type=script&lang=js&"
export * from "./BookingSlotForm.vue?vue&type=script&lang=js&"
import style0 from "./BookingSlotForm.vue?vue&type=style&index=0&id=296d38ec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "296d38ec",
  null
  
)

export default component.exports