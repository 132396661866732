import autobind from "auto-bind"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openBookingPageDialog(options)
  }

  openBookingPageDialog(options = {}) {
    const type = "BookingPageDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  bookSlotDialog(options = {}) {
    const type = "BookingSlotDialog"
    const { mode = "create", bookingNoticeId, startTime, endTime, bookingDetails } = options
    const { store } = this.entity

    const optionsParams = {
      bookingNoticeId,
      startTime,
      endTime,
      bookingDetails
    }

    store.dispatch("core/dialogStack/addDialog", { type, mode, bookingNoticeId, optionsParams })
  }

  get actionsList() {
    const self = this.entity

    return [
      {
        visible: true,
        label: "View BookingPage",
        icon: "el-icon-view",
        onClick: () => this.openBookingPageDialog({ mode: "view" })
      },
      {
        visible: true,
        label: "Edit BookingPage",
        icon: "el-icon-edit",
        onClick: () => this.openBookingPageDialog({ mode: "edit" })
      },
      // {
      //   visible: true,
      //   label: `${capitalize(statusChangeText)} BookingPage`,
      //   icon: isApproved ? "el-icon-close" : "el-icon-check",
      //   onConfirm: () =>
      //       self.Api.update({ id: self.id, status: isApproved ? "deactivated" : "activated" }),
      //   confirmProps: {
      //     title: `Are you sure you want to ${statusChangeText} this BookingPage?`,
      //     confirmButtonText: `${capitalize(statusChangeText)} BookingPage`
      //   }
      // },
      {
        visible: true,
        divided: true,
        class: "danger",
        label: "Delete",
        icon: "el-icon-delete",
        onConfirm: () => self.Api.destroy(self),
        confirmProps: {
          title: "Are you sure you want to delete this BookingPage?",
          confirmButtonText: "Delete BookingPage"
        }
      }
    ].filter(({ visible }) => visible)
  }
}

export default Actions
