import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import moment from 'moment'

class CspConfiguration extends ExtendedModel {
  static entity = "CspConfiguration"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get Created() {
    return moment(this.createdAt).format("DD/MM/YYYY - hh:mm A")
  }

  get Updated() {
    return moment(this.updatedAt).format("DD/MM/YYYY - hh:mm A")
  }

  get valueList() {
    const {id} = this
    return {label: this.rule, value: id, id}
  }

}
export default CspConfiguration
