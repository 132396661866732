<template>
  <div class="total-bookings">
    <div class="icon">
      <ClientManagementIcon color="var(--main-brand)" />
    </div>
    <h3>Bookings</h3>
    <div class="number">
      {{ stats0 }}
      / <span class="brand">{{ stats7 }}</span>
    </div>
    <div class="title">
      Total / <span class="brand">last 7 days</span>
    </div>
  </div>
</template>

<script>
  import { KeyStatistic } from "@/Modules/OdysseyModels"
  export default {
    name: "TotalBookings",
    data() {
      return {
        stats0: null,
        stats7: null
      }
    },
    mounted() {
      this.getBookingsCount(0)
      this.getBookingsCount(7)
    },
    methods: {
      async getBookingsCount(days) {
        this.isLoading = true
        const response = await KeyStatistic.api().fetchByType("bookings", days)
        this[`stats${days}`] = response.response.data.meta.total
        this.isLoading = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  .brand {
    color: var(--main-brand);
  }
</style>