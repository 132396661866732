<template>
  <div
      v-if="bookingNotice !== null"
      class="max-width-container">
    <h4>
      Booking Summary for Booking #{{ bookingNotice.id }} | <strong>{{ bookingNotice.date | formatDate }}</strong>
    </h4>
    <div

        v-if="bookingSite"
        v-loading="bookingSite === null"
        class="site-address">
      <h5>Site Details</h5>
      <strong>{{ bookingSite.title }}</strong>

      <dl>
        <dt>Address: </dt>
        <dd>{{ bookingSite.street }}, {{ bookingSite.city }} {{ bookingSite.zip }} {{ bookingSite.state }} </dd>
      </dl>
      <dl v-if="bookingSite.contactEmail">
        <dt>Contact Email: </dt>
        <dd>{{ bookingSite.contactEmail }}</dd>
      </dl>
      <dl v-if="bookingSite.contactPhone">
        <dt>Contact Phone: </dt>
        <dd>{{ bookingSite.contactPhone }}</dd>
      </dl>
    </div>
    <div class="booking-slots">
      <h5>Booking Slots</h5>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Schedule</th>
            <th>Start Time</th>
            <th>End Time</th>
            <th>Description</th>
            <th>Slots (TotalHeUsed)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
              v-for="(block, index) in bookingNotice.timeBlocks"
              :key="index">
            <td
                style="width: 5%;">
              {{ index + 1 }}
            </td>
            <td style="width: 10%">
              {{ block.timeStart | formatTime }}
            </td>
            <td style="width: 10%">
              {{ block.timeEnd | formatTime }}
            </td>
            <td style="width: 10%">
              {{ block.description | formatUnderscore }}
            </td>
            <td style="width: 10%">
              {{ block.bookingSlots }} / {{ block.used }}
            </td>
            <td style="width: 5%">
              <CoreButton @click="handleBookingClick(block)">
                Book
              </CoreButton>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { BookingNotice, Site, BookingPage } from "@/Modules/OdysseyModels"
import moment from 'moment'

export default {
  name: "BookingPagesTableView",
  filters: {
    formatUnderscore(string) {
      return string.replace("_", " ")
    },
    formatTime(data) {
      return moment(data).format('H:mma')
    },
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY')
    }
  },
  data() {
    return {
      bookingNotice: null,
      site: null
    }
  },
  computed: {
    bookingSite() {
      return Site.find(this.bookingNotice.siteId)
    }
  },
  async mounted() {
    const bookingNoticeId = this.$route.params.id
    if (bookingNoticeId) {
      await BookingNotice.api().fetchById(bookingNoticeId)
      this.bookingNotice = BookingNotice.find(bookingNoticeId)
      await Site.api().fetchById(this.bookingNotice.siteId)
    }
  },
  methods: {
    handleBookingClick(block) {
      const slot = {
        bookingNoticeId: this.bookingNotice.id,
        startTime: block.startTime,
        endTime: block.endTime,
        bookingDetails: {
          site: this.bookingSite,
          bookingNotice: this.bookingNotice
        }
      }
      new BookingPage().Actions.bookSlotDialog(slot)
    }
  }
}
</script>

<style lang="scss">
.max-width-container {
  max-width: 1900px;

  table {
    width: 100%;
    border: 1px solid #eaeaea;
    text-align: left;
    margin-bottom: var(--margin-xl);

    th {
      text-align: left;
      padding: 10px;
    }
    tr {
      transition: ease-in-out all 0.2s;
      &:nth-child(odd) {
        td {
          background: #FAFAFA;
        }
      }
      &:hover {
        td {
          background: #f1f3fa;
        }
      }
    }
    td {
      padding: 10px;
    }

  }
  .site-address {
   background: #f1f3fa;
    padding: var(--padding-s) var(--padding-l);
    dl {
      display: flex;
      justify-content: flex-start;

      dt {
        font-weight: bold;
        min-width: 150px
      }

      dd {
      }
    }
  }
}
</style>
