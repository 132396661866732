import autobind from "auto-bind"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openSitePreferenceDialog(options)
  }

  openSitePreferenceDialog(options = {}) {
    const type = "SitePreferenceDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  get actionsList() {
    const self = this.entity

    return [
      {
        visible: true,
        label: "View SitePreference",
        icon: "el-icon-view",
        onClick: () => this.openSitePreferenceDialog({ mode: "view" })
      },
      {
        visible: true,
        label: "Edit SitePreference",
        icon: "el-icon-edit",
        onClick: () => this.openSitePreferenceDialog({ mode: "edit" })
      },
      // {
      //   visible: true,
      //   label: `${capitalize(statusChangeText)} SitePreference`,
      //   icon: isApproved ? "el-icon-close" : "el-icon-check",
      //   onConfirm: () =>
      //       self.Api.update({ id: self.id, status: isApproved ? "deactivated" : "activated" }),
      //   confirmProps: {
      //     title: `Are you sure you want to ${statusChangeText} this SitePreference?`,
      //     confirmButtonText: `${capitalize(statusChangeText)} SitePreference`
      //   }
      // },
      {
        visible: true,
        divided: true,
        class: "danger",
        label: "Delete",
        icon: "el-icon-delete",
        onConfirm: () => self.Api.destroy(self),
        confirmProps: {
          title: "Are you sure you want to delete this SitePreference?",
          confirmButtonText: "Delete SitePreference"
        }
      }
    ].filter(({ visible }) => visible)
  }
}

export default Actions
