<template>
  <div class="time-block-selector-creator-container">
    <h4>Time Block #{{ timeBlockIndex + 1 }}</h4>
    <div
        class="form-builder-field-group">
      <div
          class="form-builder-field-wrapper flex-column field-width select"
          style="--fraction: 12">
        <div class="form-builder-label-container">
          <core-text
              tag="label"
              semibold
              class="el-form-item__label truncate">
            Time Block Description
          </core-text>
        </div>
        <el-form-item>
          <el-select
              v-model="value.description"
              filterable
              allow-create>
            <el-option
                v-for="(description, index) in descriptionOptions"
                :key="`desc-${index}`"
                :value="description.value"
                :label="description.label" />
          </el-select>
        </el-form-item>
      </div>

      <div
          class="form-builder-field-wrapper flex-column field-width text"
          style="--fraction: 12">
        <div class="form-builder-label-container">
          <core-text
              tag="label"
              semibold
              class="el-form-item__label truncate">
            Inspection Date
          </core-text>
        </div>
        <el-form-item>
          <el-date-picker
              v-model="value.startDate"
              type="date"
              format="dd/MM/yyyy"
              placeholder="Pick a day" />
        </el-form-item>
      </div>
    </div>
    <div
        class="form-builder-field-group">
      <div
          class="form-builder-field-wrapper flex-column field-width"
          :style="`--fraction: 12`">
        <div class="form-builder-label-container">
          <core-text
              tag="label"
              semibold
              class="el-form-item__label truncate">
            Block Start Time
          </core-text>
        </div>
        <el-form-item>
          <el-time-picker
              v-model="value.startTime"
              type="date"
              format="hh:mm A"
              placeholder="Pick a start time" />
        </el-form-item>
      </div>

      <div
          class="form-builder-field-wrapper flex-column field-width"
          :style="`--fraction: 12`">
        <div class="form-builder-label-container">
          <core-text
              tag="label"
              semibold
              class="el-form-item__label truncate">
            Block End Time
          </core-text>
        </div>
        <el-form-item>
          <el-time-picker
              v-model="value.endTime"
              type="date"
              format="hh:mm A"
              placeholder="Pick an end time" />
        </el-form-item>
      </div>

      <div
          class="form-builder-field-wrapper flex-column field-width"
          :style="`--fraction: 12`">
        <div class="form-builder-label-container">
          <core-text
              tag="label"
              semibold
              class="el-form-item__label truncate">
            Booking Slots
          </core-text>
        </div>
        <el-form-item>
          <el-input-number
              v-model="value.bookingSlots"
              size="small"
              :min="1"
              :max="50" />
        </el-form-item>
      </div>
    </div>
    <div
        v-if="timeBlockIndex > 0"
        class="button-wrapper">
      <el-popconfirm
          confirm-button-text="Delete Time Block"
          cancel-button-text="Cancel"
          icon="el-icon-info"
          icon-color="red"
          title="Are you sure you want to delete this Time Block?"
          @confirm="deleteTimeBlock">
        <el-button
            slot="reference"
            icon="el-icon-delete"
            size="small">
          Delete Time Block
        </el-button>
      </el-popconfirm>
    </div>
  </div>
</template>

<script>
  export default {
    name: "TimeBlockSelectorCreator",
    props: {
      timeBlockIndex: {
        type: Number,
        required: true
      },
      value: {
        type: Object,
        required: true
      }
    },
    data() {
      return {

        descriptionOptions: [
          {
            label: 'Unit inspection',
            value: 'unit_inspection'
          },
          {
            label: 'Fire door repairs',
            value: 'fire_door_repairs'
          },
          {
            label: 'Smoke alarm repairs',
            value: 'smoke_alarm_repairs'
          },
          {
            label: 'Fire safety repairs',
            value: 'fire_safety_repairs'
          }
        ]
      }
    },
    methods: {
      deleteTimeBlock() {
        this.$emit("deleteTimeBlock", this.timeBlockIndex)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .time-block-selector-creator-container {
    background: rgba(0,0,0,0.05);
    border-bottom: 3px solid var(--main-border-color);
    padding-top: var(--padding-m);
    margin-bottom: var(--margin-l);

    h4 {
      margin: 0 0 0 var(--margin-s);
    }

    .form-builder-field-group {
      display: flex;
      flex-wrap: wrap;

      .form-builder-field-wrapper {
        padding: 0 var(--padding-s);
        padding-bottom: var(--padding-xl);

        &.field-width {
          width: calc(var(--fraction) / 24 * 100%);
        }


        .el-date-editor,
        .el-select {
          width: 100%
        }
      }
    }

    .button-wrapper {
      text-align: right;
      margin: var(--margin-l);
    }
  }
</style>
