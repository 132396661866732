<template>
  <div class="the-stylex-container">
    <div class="top-section-wrapper">
      <TopContainer>
        <TotalPatients />
      </TopContainer>

      <TopContainer>
        <TotalBookings />
      </TopContainer>

      <TopContainer>
        <TotalAppointmentNotes />
      </TopContainer>

      <TopContainer>
        <TotalLocations />
      </TopContainer>

      <TopContainer>
        <TotalGps />
      </TopContainer>

      <!--      <TopContainer>-->
      <!--        <TotalOrders />-->
      <!--      </TopContainer>-->
    </div>
    <div class="section-wrapper">
      <!--      <UpcomingBookings />-->
    </div>
  </div>
</template>

<script>
  import  TopContainer from "@/Modules/Dashboard/components/TopContainer"
  import TotalPatients from "@/Modules/Dashboard/components/stats/TotalPatients"
  import TotalBookings from "@/Modules/Dashboard/components/stats/TotalBookings"
  import TotalLocations from "@/Modules/Dashboard/components/stats/TotalLocations"
  import TotalGps from "@/Modules/Dashboard/components/stats/TotalGps"
  import TotalAppointmentNotes from "@/Modules/Dashboard/components/stats/TotalAppointmentNotes"

  export default {
    name: "Otocare",
    components: {
      TotalPatients,
      TopContainer,
      TotalBookings,
      TotalLocations,
      TotalGps,
      TotalAppointmentNotes
    }
  }
</script>

<style lang="scss" scoped>
  .top-section-wrapper {
    display: flex;
    flex-wrap: wrap;
    div {
      min-width: 15em;
      flex: 1;
      margin: 1em;
    }
  }
</style>