<template>
  <el-dropdown
      ref="dropdown"
      trigger="click">
    <el-badge
        :class="{ animate: animate }"
        :value="notificationCount"
        :hidden="!notificationCount"
        :max="10">
      <i
          class="icon"
          :class="{ active: notificationCount > 0, [icon]:true }" />
    </el-badge>

    <el-dropdown-menu
        slot="dropdown"
        class="alerts-drop-down">
      <header class="alerts-drop-down-header flex items-center justify-between">
        <core-text tag="h3">
          {{ title }}
        </core-text>

        <div class="buttons flex items-center">
          <CoreActionDropdown
              v-if="showActions"
              :key="`${title}_actions_dropdown`"
              size="small"
              :actions="actionsWithClose" />
          <i
              v-else
              class="el-icon-close close-icon"
              @click="closeDropdown" />
        </div>
      </header>

      <main class="dropdown-content">
        <slot />
      </main>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { uuid as uuidv4 } from "uuidv4"

export default {
  name: "CoreAlertsDropdownV2",
  props: {
    icon: { type: String, default: "el-icon-message-solid" },
    title: { type: String, default: "Alerts & Notifications" },
    notificationCount: { type: Number, default: 0 },
    actions: { type: Array, default: () => [] }
  },
  data() {
    return {
      animate: false,
      uuid: uuidv4()
    }
  },
  computed: {
    actionsWithClose() {
      return [
        ...this.actions,
        { label: "Close", icon: "el-icon-close", onClick: this.closeDropdown }
      ]
    },
    showActions() {
      if (this.actionsWithClose.length > 1) {
        return true
      }

      return false
    }
  },
  watch: {
    notificationCount: {
      immediate: true,
      handler() {
        const { notificationCount, uuid } = this

        if (notificationCount) {
          this.animate = true

          new Audio(`${process.env.BASE_URL}chime1.mp3`).play()

          setTimeout(() => (this.animate = false), 2000)
        }

        this.$store.dispatch("core/alerts/addTotalNotificationCount", {
          key: uuid,
          count: notificationCount
        })
      }
    }
  },
  mounted() {
    const { uuid } = this

    this.$store.dispatch("core/alerts/addTotalNotificationCountKey", {
      key: uuid
    })
  },
  beforeDestroy() {
    const { uuid } = this

    this.$store.dispatch("core/alerts/removeTotalNotificationCountKey", {
      key: uuid
    })
  },
  methods: {
    closeDropdown() {
      this.$refs.dropdown.hide()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep  .el-badge.animate {
  transform: scale(1);
  animation: pulse 0.7s infinite;
}

.alerts-drop-down {
  background: white;
  width: 30em;
  z-index: 20;
  padding: 0;

  & .alerts-drop-down-header {
    padding: var(--padding-s) var(--padding-m);
    border-bottom: 2px solid lightgrey;

    .close-icon {
      font-size: 1.5em;
      cursor: pointer;
      transition: all 0.2s;
      height: fit-content;
      color: #000;

      &:hover {
        transform: scale(1.1);
        color: rgba(0, 0, 0, 0.3);
      }
    }
  }

  main.dropdown-content {
    max-height: 70vh;
    padding: var(--padding-l) 0;
    overflow-y: auto;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.95) rotate(10deg);
  }

  70% {
    transform: scale(1.2) rotate(-10deg);
  }

  100% {
    transform: scale(0.95) rotate(10deg);
  }
}
</style>
