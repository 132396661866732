import autobind from "auto-bind"

class Actions {
  constructor(entity) {
    this.entity = entity
    autobind(this)
  }

  openDialog(options = {}) {
    this.openPatientDialog(options)
  }

  openPatientDetails(options = {}) {
    const type = "PatientDetails"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  openPatientDialog(options = {}) {
    const type = "PatientDialog"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  openBookingDialog(options = {}) {
    const type = "BookingDialog"
    const { mode = "view" } = options
    const { store } = this.entity
    const optionsParams = {
      patientId: this.entity.id,
      locationId: this.entity.locationId
    }

    store.dispatch("core/dialogStack/addDialog", { type, mode, optionsParams })
  }

  openImportPatientsDialog(options = {}) {
    const type = "ImportPatients"
    const { mode = "view" } = options
    const { id, store } = this.entity

    store.dispatch("core/dialogStack/addDialog", { type, mode, id })
  }

  get actionsList() {
    const self = this.entity

    return [
      {
        visible: true,
        label: "View Patient File",
        icon: "el-icon-view",
        onClick: () => this.openPatientDetails({ mode: "view" })
      },
      {
        visible: true,
        label: "Edit Patient",
        icon: "el-icon-edit",
        onClick: () => this.openPatientDialog({ mode: "edit" })
      },
      {
        divided: true,
        visible: true,
        label: "Create Booking for Patient",
        icon: "el-icon-date",
        onClick: () => this.openBookingDialog({ mode: "create" })
      },
      {
        visible: true,
        divided: true,
        class: "danger",
        label: "Delete",
        icon: "el-icon-delete",
        onConfirm: () => self.Api.destroy(self),
        confirmProps: {
          title: "Are you sure you want to delete this Patient?",
          confirmButtonText: "Delete Patient"
        }
      }
    ].filter(({ visible }) => visible)
  }
}

export default Actions
