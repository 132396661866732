import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    specialOrder: 1,
    internationalShipping: null,
    engraving: null,
    clickAndCollect: null,
    redisCacheLifetime: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition