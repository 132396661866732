import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import { startCase } from 'lodash'
import moment from "moment/moment";

class BookingNotice extends ExtendedModel {
  static entity = "BookingNotice"
  static globalSearchEnabled = false
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get NoticeType() {
    return startCase(this.noticeType)
  }

  get NoticeDate() {
    return moment(this.date).format("DD/MM/YYYY")
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, id}
  }

}
export default BookingNotice
