<template>
  <div
      v-loading.fullscreen.lock="loading"
      element-loading-background="rgba(0, 0, 0, 0.6)">
    <el-dialog
        v-show="showDialog"
        :id="dialogProps.uuid"
        class="el-overrides"
        :title="title"
        :append-to-body="true"
        :close-on-click-modal="false"
        :visible="true"
        :before-close="handleClose"
        :top="top"
        :width="width">
      <core-text
          slot="title"
          truncate
          class="el-dialog__title">
        {{ title }}
      </core-text>

      <template v-if="hasDialogProps && !loading">
        <el-tabs
            v-if="mutatedTabs"
            v-model="currentTab">
          <el-tab-pane
              v-for="(tab) in mutatedTabs"
              :key="tab.label"
              :label="tab.label"
              :name="tab.name">
            <div
                v-if="showEditCreateSwitcher(tab.modeSwitcherEnabled)"
                class="flex justify-end">
              <el-button
                  size="mini"
                  class
                  @click="changeMode">
                <core-text capitalize>
                  <i :class="modeSwitcherIcon" />
                  {{ modeSwitcherLabel }}
                </core-text>
              </el-button>
            </div>
            <component
                :is="tab.Component"
                v-bind="tab.props"
                :dialog-props="dialogProps" />
          </el-tab-pane>
        </el-tabs>
        
        <template v-else>
          <div
              v-if="showEditCreateSwitcher(modeSwitcherEnabled)"
              class="flex justify-end">
            <el-button
                size="mini"
                class
                @click="changeMode">
              <core-text capitalize>
                <i :class="modeSwitcherIcon" />
                {{ modeSwitcherLabel }}
              </core-text>
            </el-button>
          </div>
          <slot
              name="content"
              class="content"
              v-bind="{...$props, ...$attrs}" />
        </template>
      </template>

      <el-alert
          v-else
          title="Developer-Error"
          description="You must pass the 'dialogProps' prop into CoreDialog for it to work properly. This is passed into the component you have tried to use the CoreDialog component in."
          type="error"
          :closable="false" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "CoreDialog",
  props: {
    title: { type: String, default: null },
    tabs: { type: Array, default: null },
    onClose: { type: Function, default: () => null },
    loading: { type: Boolean, default: null },
    modeSwitcherEnabled: { type: Boolean, default: null },
    top: { type: String, default: "3vh" },
    width: { type: String, default: "50%" },
    dialogProps: { type: Object, required: true }
  },
  data() {
    return {
      currentTab: null,
      allBackgroundElements: []
    }
  },
  computed: {
    isCreateMode() {
      return this.mode === "create"
    },
    isBulkEditMode() {
      return this.mode === "bulkedit"
    },
    mode() {
      return this.dialogProps.mode
    },
    showDialog() {
      return !this.loading
    },
    hasDialogProps() {
      return this.dialogProps
    },
    modeSwitcherLabel() {
      const { mode } = this
      return mode === "edit" ? "View Mode" : "Edit Mode"
    },
    modeSwitcherIcon() {
      const { mode } = this

      return mode === "edit" ? "el-icon-view" : "el-icon-edit"
    },
    mutatedTabs() {
      if (!this.tabs) {
        return null
      }

      return this.tabs.map(tab => ({
        ...tab,
        name: tab.label.split(" ").join("")
      }))
    }
  },
  watch: {
    currentTab() {
      const { changeTab } = this.dialogProps

      changeTab(this.currentTab)
    },
    mutatedTabs: {
      immediate: true,
      handler() {
        if (this.mutatedTabs && this.mutatedTabs.length && !this.currentTab) {
          this.currentTab = this.getCurrentTab()
        }
      }
    }
  },
  mounted() {
    var FOCUSABLE_ELEMENTS = [
      "a[href]",
      "area[href]",
      "input:not([disabled])",
      "select:not([disabled])",
      "textarea:not([disabled])",
      "button:not([disabled])",
      "iframe",
      "object",
      "embed",
      "[contenteditable]",
      '[tabindex]:not([tabindex^="-"])'
    ]

    this.allBackgroundElements = document.querySelectorAll(
      FOCUSABLE_ELEMENTS.map(el => `#app ${el}`).join(", ")
    )

    this.allBackgroundElements.forEach(el => {
      el.setAttribute("tabindex", "-1")
      el.setAttribute("aria-hidden", "true")
    })
  },
  beforeDestroy() {
    this.allBackgroundElements.forEach(el => {
      el.setAttribute("tabindex", "0")
      el.setAttribute("aria-hidden", "false")
    })
  },

  methods: {
    showEditCreateSwitcher(modeSwitcherEnabled) {
      const { isCreateMode, isBulkEditMode } = this

      return modeSwitcherEnabled && !isCreateMode && !isBulkEditMode
    },
    changeMode() {
      const { mode, changeMode } = this.dialogProps
      const nextMode = mode === "edit" ? "view" : "edit"

      changeMode(nextMode)
    },
    getCurrentTab() {
      const { currentTab = "" } = this.dialogProps

      if (!currentTab) {
        return this.mutatedTabs[0].name
      }

      if (currentTab.includes("tab")) {
        let tabNavIndex = currentTab.replace("tab", "")

        if (tabNavIndex > this.mutatedTabs.length) {
          tabNavIndex = this.mutatedTabs.length - 1
        }

        return this.mutatedTabs[tabNavIndex - 1].name
      }

      return currentTab
    },
    handleClose() {
      this.dialogProps.closeDialog()
      this.onClose()
    }
  }
}
</script>

<style lang="scss">
// Dialog
.el-dialog__wrapper .el-dialog {
  overflow: hidden;
  border-radius: var(--border-radius-s);
  position: relative;
  box-shadow: var(--box-shadow-container);
}
.el-dialog__headerbtn {
  top: 15px !important;
  &:focus {
    outline: 1px solid cadetblue;
  }
}
.el-dialog__header {
  background: var(--main-light-grey) !important;
  position: absolute;
  height: 7rem;
  width: 100%;
  box-shadow: var(--box-shadow-container);
  padding: 10px 20px 10px !important;
}

.el-dialog__title {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8) !important;
  margin: 0;
  padding-right: 2rem;
  font-size: 1.15em !important;
}

.el-dialog__close {
  color: rgba(0, 0, 0, 0.6) !important;

  &:hover {
    color: rgba(0, 0, 0, 0.9) !important;
  }
}

.el-dialog__body {
  border-radius: var(--border-radius-s);
  position: relative;
  z-index: 10;
  background: white;
  margin-top: 3rem;
  box-shadow: var(--box-shadow-container);
  padding: var(--padding-l) var(--padding-l) !important;
}

.el-overrides {
  ::v-deep  .el-tabs__content {
    padding: 0 1em;
  }
}
</style>