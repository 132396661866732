import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config"

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/cspConfigurations"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint,
  endpoints: {
    importConfiguration: `${endpoint}/importConfiguration`
  }
})
const { endpoints } = defaults

const apiActions = {
  ...defaults.apiActions,
  importConfiguration(options = {}) {
    const url = endpoints.importConfiguration({ ...options})

    return this.post(url, options)
  }
}

const routes = server => {
  defaults.routes(server)
}

export { apiActions }
export default routes
