<template>
  <el-form-item
      :prop="fieldPath"
      :rules="validation" 
      class="time-stepper">
    <div class="time-stepper-select flex-column">
      <core-text
          v-if="props.isRange"
          class="stepper-labels"
          bold>
        {{ fromLabel }}
      </core-text>

      <core-text>{{ fromValue }}</core-text>
    </div>

    <div
        v-if="props.isRange"
        class="time-stepper-select flex-column">
      <core-text
          class="stepper-labels"
          bold>
        {{ toLabel }}
      </core-text>

      <core-text>{{ toValue }}</core-text>
    </div>
  </el-form-item>
</template>

<script>
import get from "lodash/get"

const mutateTimeValue = value => {
  if (!value) {
    return null
  }

  const date = new Date(value)
  let minutes = date.getMinutes()
  let hours = date.getHours()

  if (minutes === 5) {
    minutes = "05"
  }

  if (minutes == 0) {
    minutes = "00"
  }

  if (hours >= 0 && hours <= 9) {
    hours = `0${hours}`
  }

  return `${hours}:${minutes}`
}

export default {
  props: {
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] }
  },
  computed: {
    fromLabel() {
      return get(this.props, "fromLabel", "From")
    },
    toLabel() {
      return get(this.props, "toLabel", "To")
    },
    fromValue() {
      return this.getValueFromTime(get(this.$attrs, "value.from", null)) || "N/A"
    },
    toValue() {
      return this.getValueFromTime(get(this.$attrs, "value.to", null)) || "N/A"
    },
    props() {
      return { ...this.$attrs }
    }
  },
  methods: {
    getValueFromTime(time) {
      return mutateTimeValue(time)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep  .el-select {
  width: 100%;
}

.time-stepper {
  ::v-deep  .el-form-item__content {
    display: flex;
    line-height: 30px;
  }

  .time-stepper-select {
    width: 45%;
    &:first-child {
      margin-right: auto;
    }
  }
}
</style>
