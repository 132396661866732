import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config";

const baseURL = config.apiConfig.lightwerk.api
const endpoint = "/orders"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint,
  endpoints: {
    getOrderItems: "/orders/getOrderItems/:id",
    getOrder: "/orders/getOrder/:id",
  }
})

const { endpoints } = defaults

const apiActions = {
  ...defaults.apiActions,
  getOrderItems(id = "", options = {}) {
    const url = endpoints.getOrderItems({ ...options, id })

    return this.get(url, options)
  },
  getOrder(id = "", options = {}) {
    const url = endpoints.getOrder({ ...options, id })
    return this.get(url, options)
  }
}

const routes = server => {
  defaults.routes(server)
}

export { apiActions }
export default routes
