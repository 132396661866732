import BookingPages from "./BookingPages"
import BookingPagesIcon from "./components/BookingPagesIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const BookingPagesModule = {
  moduleGrouping: "Customer Management",
  name: "BookingPages",
  menuName: "Booking Pages",
  component: BookingPages,
  path: "BookingPages",
  icon: BookingPagesIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View BookingPages data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("BookingPages", BookingPages)
    Vue.component("BookingPagesIcon", BookingPagesIcon)
  }
}
export default BookingPagesModule
