import { render, staticRenderFns } from "./BookingPagesIcon.vue?vue&type=template&id=ed9e1396&scoped=true&"
import script from "./BookingPagesIcon.vue?vue&type=script&lang=js&"
export * from "./BookingPagesIcon.vue?vue&type=script&lang=js&"
import style0 from "./BookingPagesIcon.vue?vue&type=style&index=0&id=ed9e1396&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed9e1396",
  null
  
)

export default component.exports