import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config"

const baseURL = config?.apiConfig?.lightwerk?.api
const endpoint = "/noticeTemplates"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint
})

const apiActions = {
  ...defaults.apiActions
}

const routes = server => {
  defaults.routes(server)
}

export { apiActions }
export default routes
