<template>
  <div class="top-container">
    <slot />
  </div>
</template>

<script>
  export default {
    name: "TopContainer"
  }
</script>

<style lang="scss" scoped>
.top-container {
  background: white;
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: var(--box-shadow-button);

  ::v-deep  .number {
    font-size: 1.6em;
  }

  ::v-deep  .title {
    font-size: 1.3em;
  }
  ::v-deep  .icon {
    width: 3em;
  }
}
</style>