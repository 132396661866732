import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"

class CaneToadProduct extends ExtendedModel {
  static entity = "CaneToadProduct"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get timeBlock() {
    return `${this.timeFrame} min`
  }

  get productPrice() {
    return `${parseFloat(this.price).toFixed(2)} AUD`
  }

  get valueList() {
    const {id} = this
    return {label: this.title, value: id, id, extra: this.categoryTitle}
  }

  get productThumbnail() {
    if (this.ImageList && this.ImageList.length > 0) {
      return `https://dev.api.canetoadequip.com.au/pic/304x304/media/productImages/${this.ImageList[0]}`
    }
  }

}
export default CaneToadProduct
