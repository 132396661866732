import routes, { apiActions } from "./routes"

const attributes = {
    ID: 1,
    AvailableSellQuantity: null,
    Brand: null,
    Categories: null,
    CostPrice: null,
    DateAdded: null,
    DefaultPrice: null,
    Description: null,
    Features: null,
    ImageUrl: null,
    ImageList: null,
    InventoryId: null,
    ItemSpecifics: null,
    Name: null,
    RRP: null,
    SKU: null,
    ShortDescription: null,
    Specifications: null,
    Subtitle: null,
    WarehouseQuantity: null,
    Warranty: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition