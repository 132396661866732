import ExtendedModel from "@/Modules/Core/utils/extended-vue-orm-model"
import definition, { apiActions } from "./definition"
import Actions from "./actions"
import moment from "moment"

class Customer extends ExtendedModel {
  static entity = "Customer"
  static globalSearchEnabled = true
  static definition = definition

  static apiConfig = {
    dataKey: "data.data",
    actions: apiActions
  }

  get Actions() {
    return new Actions(this)
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get globalSearchLabel() {
    return this.fullName
  }

  get birthday() {
    if (this.dob) {
      return `${moment(this.dob).format("DD MMMM")} (Age ${moment().diff(this.dob, 'years')})`
    } else {
      return "N/A"
    }
  }

  get valueList() {
    const {id} = this
    return {label: this.fullName, value: id, extra: this.email, id}
  }

}
export default Customer
