<template>
  <div class="tabbed-container fit-parent">
    <aside>
      <ul class="tabbed-navigation">
        <router-link
            v-for="(item, index) in items"
            :key="`tabbed-${index}`"
            :to="item.url"
            tag="li">
          {{ item.title }}
        </router-link>
      </ul>
    </aside>
    <main>
      <component :is="activeComponent" />
    </main>
  </div>
</template>

<script>
export default {
  name: "TabbedContent",
  props: {
    items: {
      type: Object,
      required: true
    },
    activeComponent: {
      type: [String],
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbed-container {
  background: white;
  display: flex;
  flex-wrap: wrap;
  border-radius: 1em;
  box-shadow: var(--box-shadow-container);

  aside {
    flex: 1;
    padding-top: 2em;
    ul.tabbed-navigation {
      list-style: none;

      li {
        display: block;
        padding: 1em;
        border-bottom: 1px solid var(--main-darker-grey);
        transition: var(--main-transition);
        cursor: pointer;

        &.router-link-active,
        &:hover {
          box-shadow: inset 3px 0 0px var(--main-brand);
          background: var(--main-light-grey);
        }
      }
    }
  }
  main {
    flex: 3;
    border-left: 1px solid var(--main-darker-grey);

    ::v-deep  h1 {
      margin-left: 1em;
      padding: 0;
    }
  }
}
</style>
