<template>
  <div class="time-block-selector-container">
    <h3>Time Block Creator</h3>

    <TimeBlockSelectorCreator
        v-for="(timeBlock, index) in timeBlocks"
        :key="`timeblock-${index}`"
        v-model="timeBlocks[index]"
        :prop="fieldPath"
        :time-block-index="index"
        @deleteTimeBlock="deleteTimeBlock" />

    <div class="button-wrapper">
      <el-button
          size="small"
          @click="addTimeBlock">
        + Add Time Block
      </el-button>
    </div>
  </div>
</template>

<script>
  import TimeBlockSelectorCreator
    from "@/Modules/Core/components/FormBuilder.v2/ui/EditModes/TimeBlockSelector/TimeBlockSelectorCreator";
  export default {
    name: "TimeBlockSelector",
    components: {
      TimeBlockSelectorCreator
    },
    props: {
      formMeta: { type: Object, default: () => ({}) },
      value: { type: [Array, String, Object], default: () => [] },
      label: { type: String, default: "" },
      disabled: { type: Boolean, default: false },
      size: { type: String, default: "medium" },
      fieldPath: { type: String, default: "" },
    },
    data() {
      return {
        timeBlocks: [],
      }
    },
    watch: {
      timeBlocks: {
        handler(val) {
          this.$emit('input', val)
        },
        deep: true
      }
    },
    mounted() {
      if (this.value.length < 1) {
        this.timeBlocks.push({
          description: '',
          startDate: new Date(),
          startTime: null,
          endTime: null,
          bookingSlots: 0,
        })
      } else {
        this.timeBlocks = JSON.parse(JSON.stringify(this.value))
      }
    },
    methods: {
      deleteTimeBlock(index) {
        this.timeBlocks.splice(index, 1)
      },
      addTimeBlock() {
        this.timeBlocks.push({
          description: '',
          startDate: new Date(),
          startTime: null,
          endTime: null,
          bookingSlots: 0,
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .time-block-selector-container {


    h3 {
      padding: var(--padding-s) 0 0 var(--padding-s);
    }

    .button-wrapper {
      margin: var(--margin-l) 0 var(--margin-l) 0;
    }

  }
</style>