// import UserManagement from "@/Modules/UserManagement"
// import AppointmentNotesManagement from "@/Modules/AppointmentNotesManagement"

// import ClientManagement from "@/Modules/ClientManagement"
// import PatientManagement from "@/Modules/PatientManagement"
// import OrderManagement from "@/Modules/OrderManagement"
// import FMSBookings from "@/Modules/FMSBookings"
// import SiteManagement from "@/Modules/SiteManagement"
// import GeneralPractitionersManagement from "@/Modules/GeneralPractitionersManagement"

// import ClientAlbums from "@/Modules/ClientAlbums"
// import ProductManagement from "@/Modules/ProductManagement"
// import PointOfSale from "@/Modules/PointOfSale"
// import SystemSettings from "@/Modules/SystemSettings"
// import NoticeTemplateBuilder from "@/Modules/NoticeTemplateBuilder";
// import BookingNotices from "@/Modules/BookingNotices";
import BookingPages from '@/Modules/BookingPages';
export default [
  // PatientManagement,
  // FMSBookings,
  // PointOfSale,
  // OrderManagement,
  // ClientAlbums,
  // ProductManagement,
  // AppointmentNotesManagement,
  // GeneralPractitionersManagement,
  // BookingNotices,
  BookingPages,
  // SiteManagement,
  // UserManagement,
  // SystemSettings,
]
