<template>
  <nav class="tab-router flex-auto">
    <template v-for="(tab, index) in handledTabs">
      <component
          :is="getTabType(tab)"
          v-if="tab.to"
          :key="index"
          :tab="tab">
        <div
            v-if="tab.onClose"
            slot="close-button"
            class="close-icon">
          <el-popconfirm
              v-if="tab.confirmCloseProps"
              v-bind="{...defaultConfirmProps, ...tab.confirmCloseProps}"
              @confirm="tab.onClose(tab.id)">
            <i
                slot="reference"
                class="el-icon-close" />
          </el-popconfirm>
          <i
              v-else
              class="el-icon-close"
              @click="tab.onClose(tab.id)" />
        </div>

        <template
            v-if="tab.status"
            slot="tab-status">
          <TabStatus :status="tab.status" />
        </template>
      </component>
    </template>
  </nav>
</template>

<script>
import isFunction from "lodash/isFunction"
import get from "lodash/get"
import TabStatus from "./TabStatus"
import LinkTab from "./LinkTab"
import ClickTab from "./ClickTab"

export default {
  name: "TabRouter",
  components: { LinkTab, TabStatus },
  props: { tabs: { type: Array, default: () => [] } },
  computed: {
    handledTabs() {
      const { tabs, handleLabel } = this

      return tabs.map(tab => ({ ...tab, label: handleLabel(tab.label) }))
    },
    route() {
      return this.$route
    },
    defaultConfirmProps() {
      return {
        title: "Hey, You forgot to add a title.",
        icon: "el-icon-info",
        iconColor: "red",
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      }
    }
  },
  watch: {
    route(newValue) {
      if (get(newValue, "query.d.length", null)) {
        this.$store.dispatch("core/dialogStack/getDialogsFromUrl")
      }
    }
  },
  methods: {
    handleLabel(label) {
      return isFunction(label) ? label() : label
    },

    getTabType(tab) {
      if (tab.to) {
        return LinkTab
      }

      if (tab.onClick) {
        return ClickTab
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/Modules/Core/assets/css/index.scss";

::v-deep  .item sup {
  @extend .pulse;
}

.tab-router {
  display: flex;
  justify-content: flex-end;
  width: fit-content;
}

::v-deep  .tab-container {
  margin-right: 15px;
  font-weight: bold;
  transition: var(--main-transition);
  width: fit-content;
  border-top-left-radius: var(--border-radius-xs);
  border-top-right-radius: var(--border-radius-xs);
  overflow: hidden;
  height: 2.6em;

  .tab-inner {
    transition: all 0.002s;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    max-width: 10rem;
    background: rgba(0, 0, 0, 0.07);
    margin-bottom: 0.5em;
  }

  .tab-link {
    transition: all 0.002s;
    text-decoration: none;
    padding: 4px 15px;
  }

  &:first-child {
    margin-left: auto;
  }

  & .tab-container {
    transition: all 0.002s;
    padding: 10px 15px;
  }

  ::v-deep  .close-icon {
    padding-right: 10px;
    color: black;
    transition: all 0.002s;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }

  &.active {
    box-shadow: inset 0 -2px #409eff;
    box-shadow: var(--box-shadow-container);
    .tab-link {
      color: #409eff;
    }

    & .tab-inner {
      background: white;
    }
  }

  &:hover:not(.active) {
    box-shadow: inset 0 -2px #409eff;
    box-shadow: var(--box-shadow-container);
    .tab-link {
      color: #409eff;
    }
    & .tab-inner {
      background: white;
    }
  }

  &:active {
    .tab-link {
      box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.01);
    }
  }
}
</style>
