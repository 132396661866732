// import Modules here and add them to the routes array to see them in the Side bar menu and screen
import Dashboard from "@/Modules/Dashboard"
import Main from "./views/Main"
import config from "./config"
import modules from "./modules"

const routes = [
  {
    registerChildren: true,
    component: Main,
    path: "",
    enabled: false,
    main: true,
    meta: {
      requiresAuth: true
    },
    children: [{ ...Dashboard, path: "" }, Dashboard, ...modules],
    name: config.appConfig.appName
  },
]
export default routes
