<template>
  <div class="welcome-box">
    <div class="welcome-wrapper">
      <div class="salutation">
        G'Day <strong>{{ username }}</strong>!
      </div>

      <div class="options-bar">
        <el-popconfirm
            title="Do you really want to log out?"
            confirm-button-text="Logout"
            cancel-button-text="Cancel"
            confirm-button-type="abel"
            icon="el-icon-switch-button"
            icon-color="red"
            @confirm="logout">
          <core-button
              slot="reference"
              type="primary"
              icon="el-icon-switch-button"
              class="button">
            Logout
          </core-button>
        </el-popconfirm>
      </div>
    </div>
  </div>
</template>

<script>
import get from "lodash/get"

export default {
  name: "CoreAccountWelcomeBox",
  computed: {
    username() {
      if (this.firstName) {
        return this.firstName
      } else {
        return "Admin"
      }
    },
    firstName() {
      return get(this.$store.state, "auth.user.firstName")
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/signUserOut", { root: true }).then(() => {
        window.location.replace(window.location.origin + "/#/login")
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.welcome-box {
  padding: 1em;
  .welcome-wrapper {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
    background: white;
    padding: 0.15em 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: -1em;

    .salutation {
      padding: 0.5em 0;
      flex: 3;
    }

    .options-bar {
      display: flex;

      button {
        font-size: 0.7em;
        margin: 0;
        padding: 0.5em 0.75em;
        border-radius: 0.5em;
        border-color: transparent;
        transition: var(--main-transition);
      }
    }
  }
}
</style>
