// import TheStylex from "@/Modules/TheStylexBase/config"
// import Lightwerk from "@/Modules/LightwerkBase/config"
// import Otocare from "@/Modules/OtocareBase/config"
// import JKWMemberPortal from "@/Modules/JKWMemberPortalBase/config"
// import CaneToadEquipAdmin from '@/Modules/CaneToadEquipAdminBase/config'
// import FMS from '@/Modules/FMSBase/config'
import FMSClientBase from '@/Modules/FMSClientBase/config'
// import MHJConfigurator from '@/Modules/MHJConfiguratorBase/config'

export default FMSClientBase
