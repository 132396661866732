import createDefaultCRUD from "@/Modules/Core/utils/miragejs/createDefaultCRUD"
import config from "@/config"

const baseURL = config?.apiConfig?.lightwerk?.api
const endpoint = "/bookingNotices"

const defaults = createDefaultCRUD({
  baseURL,
  endpoint,
  endpoints: {
    getBookings: "/bookingNotices/getBookings/:id",
  }
})

const { endpoints } = defaults

const apiActions = {
  ...defaults.apiActions,
  getBookings(id = "", options = {}) {

    const url = endpoints.getBookings({ ...options, id })

    return this.post(url, options)
  },
}

const routes = server => {
  defaults.routes(server)
}

export { apiActions }
export default routes
