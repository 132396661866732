<template>
  <section class="core-layout-side-nav">
    <main
        class="core-layout-side-nav__main-screen flex-auto minified">
      <slot
          :setMinification="setMinification"
          :isMinified="isMinified">
        <header class="screen-header-main flex justify-end items-center" />

        <router-view
            :set-minification="setMinification"
            :is-minified="isMinified" />
      </slot>
    </main>
    <CoreDialogStack />
  </section>
</template>

<script>
import store from "../store"

export default {
  name: "CoreLayoutSideNav",

  data() {
    return { isMinified: false }
  },
  methods: {
    setMinification() {
      this.isMinified = !this.isMinified
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.auth.loggedIn && to.path !== "/login") {
      store.dispatch("core/routing/updateNextRoute", to.path, { root: true })
      next("/login")
    } else {
      next()
    }
  }
}
</script>

<style lang="scss" scoped>
  $transition: width 0.2s, padding 0.2s;

  $maxified-width: 17em;
  $minified-width: 5.5em;

  .core-layout-side-nav {
    height: auto;
    display: flex;
    position: relative;
    min-height: 100%;
  }

  .screen-header-main {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
    padding-left: 3em;
    box-shadow: var(--box-shadow-container);
    height: 40px;
    border-left: 1px solid #f9f9f9;
  }

  .core-layout-side-nav__side-nav {
    position: sticky;
    left: 0;
    top: 0;
    width: $maxified-width;
    height: 100%;
    z-index: 100;
    transition: $transition;
    box-shadow: var(--box-shadow-container);

    &.minified {
      width: $minified-width;
    }
  }

  .core-layout-side-nav__main-screen {
    position: relative;
    z-index: 50;
    min-height: 100%;
    background: var(--main-light-grey);
    display: flex;
    flex-direction: column;
    height: auto;
    transition: $transition;
  }
</style>
