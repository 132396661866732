import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    active: 1,
    fromUrl: null,
    toUrl: null,
    site: null,
    status: null,
}

const definition = { attributes, routes }

export { apiActions }
export default definition