import BookingSummaryView from "./views/BookingSummary"

const routes = [
  {
    path: ":id",
    name: "booking-summary-view",
    component: BookingSummaryView
  }
]

export default routes