import routes, { apiActions } from "./routes"

const attributes = {
    id: 1,
    active: 1,
    bookingNoticeId: null,
    unitNumber: null,
    level: null,
    name: null,
    phoneNumber: null,
    email: null,
    comments: null,
    timeStart: null,
    timeEnd: null
}

const definition = { attributes, routes }

export { apiActions }
export default definition