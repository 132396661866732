<template>
  <div id="main-wrapper">
    <transition
        name="fade">
      <CoreLayoutPlain />
    </transition>
  </div>
</template>

<script>

export default {
  data() {
    return {
      env: process.env.NODE_ENV
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Baloo+Paaji+2:wght@400;500;700');
#app,
#root,
#main-wrapper,
iframe,
body {
  font-family: "Baloo Paaji 2", Helvetica, Arial, sans-serif !important;

  // App specific Branding settings
  --primary-font: "Baloo Paaji 2", sans-serif !important;
  // secondary color: 341, 86%, 54%

  /*--brand-hue: 271 !important;*/
  /*--brand-sat: 86% !important;*/
  /*--brand-light: 54% !important;*/

  /*!* Primary Color *!*/
  /*--primary-hue: 216 !important;*/
  /*--primary-sat: 25% !important;*/
  /*--primary-light: 45% !important;*/

  /*!* Secondary Color *!*/
  /*--secondary-hue: 341 !important;*/
  /*--secondary-sat: 86% !important;*/
  /*--secondary-light: 54% !important;*/
}
</style>
