import createApp from "./Modules/Core"
// Main Module
// import TheStylex from "./Modules/TheStylexBase"
// import Lightwerk from "./Modules/LightwerkBase"
// import Otocare from "./Modules/OtocareBase"
// import CaneToadEquipAdmin from "@/Modules/CaneToadEquipAdminBase";
// import MHJConfigurator from "@/Modules/MHJConfiguratorBase";
// import JKWMemberPortal from "./Modules/JKWMemberPortalBase"
// import FMSBase from "./Modules/FMSBase"
import FMSClientBase from "./Modules/FMSClientBase"

// UI Framework Element UI
import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
import locale from "element-ui/lib/locale/lang/en"
import VueFilterDateFormat from "vue-filter-date-format"


export default createApp({
  Base: FMSClientBase, // Change module name here
  initialState: {useMockData: false},
  addLibraries: Vue => {
    Vue.use(ElementUI, {locale})
    Vue.use(VueFilterDateFormat)
  }
})
