<template>
  <div class="alert-widget">
    <div class="icon-container">
      <template v-for="(alertModule) in alertModules">
        <component
            :is="alertModule.Component"
            v-if="checkPermissions(alertModule)"
            :key="alertModule.key"
            :uuid="alertModule.key"
            class="item" />
      </template>
    </div>
  </div>
</template>

<script>
import CoreAlertsAndNotifications from "./CoreAlertsAndNotifications"
import getPermissions from "@/Modules/Core/utils/getPermissions"
import sum from "lodash/sum"
import config from "@/config"

export default {
  name: "AlertWidget",
  data() {
    return {
      animate: false,
      showAlertsDropDown: false
    }
  },
  computed: {
    totalNotificationCount() {
      return Object.values(this.$store.state.core.alerts.totalNotificationCount)
    },
    alertModules() {
      return [
        { Component: CoreAlertsAndNotifications, key: "core-alerts" },
        ...this.$store.state.core.alerts.alertModules
      ]
    }
  },
  watch: {
    totalNotificationCount() {
      const totalCount = sum(this.totalNotificationCount)
      const pageTitle = config.appConfig.appName

      const newTitle = totalCount
        ? `(${totalCount}) ${pageTitle}`
        : pageTitle

      document.title = newTitle
    }
  },
  methods: {
    checkPermissions(alertModule) {
      if (alertModule.permissionsKey) {
        return getPermissions(alertModule.permissionsKey)
      }

      return true
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-widget {
  display: flex;
  align-items: center;

  ::v-deep  .icon {
    transform: scale(1.5);
    color: rgba(0, 0, 0, 0.5);
  }

  ::v-deep  .active {
    color: var(--main-red);
    cursor: pointer;
    transition: ease-in-out all;
    transform: scale(1.5);

    &:hover {
      transform: scale(1.85);
    }
  }

  .icon-container {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 1rem 0 0 1rem;
    padding-right: var(--padding-m);
    padding-left: var(--padding-m);

    ::v-deep  .item {
      margin-right: var(--margin-m);
    }

    &.animate {
      ::v-deep  .el-badge {
        transform: scale(1);
        animation: pulse 0.7s infinite;

        ::v-deep  .active {
          color: black !important;
        }
      }
    }
  }

  ::v-deep  .el-badge__content {
    font-size: 0.7em;

    &.is-fixed {
      top: -4px;
      right: 6px;
    }
  }
}
</style>
