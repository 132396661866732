<template>
  <ul class="nav-wrapper">
    <div
        :key="`module-${navGroupName}`"
        class="navigation-group"
        :class="{ 'is-dashboard': isDashboard }">
      <h3
          v-if="isVisible"
          class="module"
          role="link"
          :aria-label="`Expand/Collapse Navigation for ${navGroupName}`"
          @click="onNavGroupNameClick">
        <span>{{ navGroupName }}</span>

        <i
            :class="{
              'el-icon-arrow-down': collapsed,
              'el-icon-arrow-up': !collapsed
            }" />
      </h3>

      <nav
          :class="{ show: !collapsed }"
          class="transition-grow"
          role="navigation"
          :aria-label="`${navGroupName} Menu`">
        <NavigationItem
            v-for="(item, index2) in menuItems"
            :key="`menu-item-${index2}`"
            :item="item"
            :is-minified="isMinified" />
      </nav>
    </div>
  </ul>
</template>

<script>
import NavigationItem from "./NavigationItem"

export default {
  name: "SideMenu",
  components: {
    NavigationItem
  },
  props: {
    navGroupName: {
      type: String,
      required: true
    },
    isMinified: {
      type: Boolean,
      required: true
    },
    menuItems: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      collapsed: false
    }
  },

  computed: {
    localStorageCollapsed() {
      return `menu-group-meta-${this.navGroupName}-collapsed`
    },
    isNoGroup() {
      return this.navGroupName === "noGroup" && !this.isMinified
    },
    isDashboard() {
      return this.navGroupName === "Dashboard" && !this.isMinified
    },
    isVisible() {
      if (this.isDashboard || this.isNoGroup) return false

      return !this.isMinified
    }
  },
  mounted() {
    this.collapsed = JSON.parse(localStorage.getItem(this.localStorageCollapsed))
  },
  methods: {
    onNavGroupNameClick() {
      this.collapsed = !this.collapsed
      localStorage.setItem(this.localStorageCollapsed, this.collapsed)
    }
  }
}
</script>

<style lang="scss" scoped>
ul.nav-wrapper {
  padding: 0.35em 0 0.35em 1em;
}

.navigation-group {
  list-style: none;
  padding: 0;
  box-shadow: var(--box-shadow-button);
  background: white;
  border-top-left-radius: 1em;
  border-bottom-left-radius: 1em;
  overflow: hidden;

  &.is-dashboard {
    & ::v-deep  a {
      margin-bottom: 0;
    }
  }
}

h3,
.module {
  background: var(--main-light-grey);
  text-transform: capitalize;
  padding: 0.5em 1em 0.5em 1em;
  color: var(--main-text);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1em;
  transition: var(--main-transition);
  align-items: center;
  margin: 0;
  &:hover {
    color: rgba(0, 0, 0, 0.9);
  }
}
</style>
