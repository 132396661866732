<template>
  <div
      class="time-stepper">
    <div class="time-stepper-select">
      <core-text
          v-if="props.isRange"
          tag="label"
          truncate
          class="stepper-labels"
          bold>
        {{ fromLabel }}
      </core-text>

      <el-select
          v-bind="props"
          :value="fromValue"
          prefix-icon="el-icon-search"
          @change="(value) =>onChange({from:value })">
        <i
            slot="prefix"
            class="el-input__icon el-icon-time" />

        <el-option
            v-for="item in fromOptions"
            :key="item.value"
            :disabled="item.disabled"
            :label="item.label"
            :value="item.value">
          <span style="float: left">{{ item.label }}</span>
        </el-option>
      </el-select>
    </div>

    <div class="time-stepper-select">
      <core-text
          tag="label"

          truncate
          class="stepper-labels"
          bold>
        {{ toLabel }}
      </core-text>

      <el-select
          v-if="props.isRange"
          v-bind="props"
          :value="toValue"
          @change="(value) =>onChange({to:value })">
        <i
            slot="prefix"
            class="el-input__icon el-icon-time" />
        <el-option
            v-for="item in toOptions"
            :key="item.value"
            :disabled="item.disabled"
            :label="item.label"
            :value="item.value">
          <span style="float: left">{{ item.label }}</span>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import elementUIDefaultProps from "./elementUIDefaultProps"
import range from "lodash/range"
import get from "lodash/get"

const mutateTimeValue = value => {
  if (!value) {
    return null
  }

  const date = new Date(value)
  let minutes = date.getMinutes()
  let hours = date.getHours()

  if (minutes === 5) {
    minutes = "05"
  }

  if (minutes == 0) {
    minutes = "00"
  }

  if (hours >= 0 && hours <= 9) {
    hours = `0${hours}`
  }

  return `${hours}:${minutes}`
}

const getTimeStepperOptions = (options = {}) => {
  const { invalidTimeRanges = [] } = options
  const stepperOptions = []

  const convertedInvalidTimeRanges = invalidTimeRanges.map(({ start, end }) => {
    return { start: mutateTimeValue(start), end: mutateTimeValue(end) }
  })

  let disabled = false

  range(25).forEach(hour => {
    range(12).forEach((fiveMinuteIncrements, index) => {
      let minutes = index * 5

      if (index === 0) {
        minutes = "00"
      }

      if (index === 1) {
        minutes = "05"
      }

      if (hour === 24 && minutes > "00") {
        return
      }

      let timestep = `${hour}:${minutes}`

      if (hour <= 9) {
        timestep = `0${hour}:${minutes}`
      }

      const existingStartRange = convertedInvalidTimeRanges.find(({ start }) => start === timestep)
      const existingEndRange = convertedInvalidTimeRanges.find(({ end }) => end === timestep)

      if (existingEndRange) {
        disabled = false
      }

      if (
        (existingStartRange && timestep === "00:00") ||
        (existingEndRange && timestep === "24:00")
      ) {
        disabled = true
      }

      stepperOptions.push({ label: timestep, value: timestep, disabled })

      if (existingStartRange) {
        disabled = true
      }
    })
  })

  return stepperOptions
}

export default {
  props: {
    fromLabel: { type: String, default: "From" },
    toLabel: { type: String, default: "To" },
    fieldPath: { type: String, default: "" },
    validation: { type: Array, default: () => [] }
  },
  computed: {
    fromValue() {
      return this.getValueFromTime(get(this.$attrs, "value.from", null))
    },
    toValue() {
      return this.getValueFromTime(get(this.$attrs, "value.to", null))
    },
    allOptions() {
      const { stepperOptions } = this.props
      const startTime = mutateTimeValue(stepperOptions.start)
      const endTime = mutateTimeValue(stepperOptions.end)

      const allOptions = getTimeStepperOptions(stepperOptions).filter(timestep => {
        if (startTime && endTime) {
          return timestep.value >= startTime && timestep.value <= endTime
        }

        return true
      })

      return allOptions.map((option, index) => {
        const previousOptionIsDisabled = get(allOptions, `[${index - 1}].disabled`, true)
        const nextOptionIsDisabled = get(allOptions, `[${index + 1}].disabled`, true)

        if (previousOptionIsDisabled && nextOptionIsDisabled) {
          option.disabled = true
        }

        return option
      })
    },
    fromOptions() {
      const { toValue, allOptions } = this

      if (toValue) {
        const index = allOptions.findIndex(option => option.value === toValue)

        return allOptions.slice(0, index)
      }

      return allOptions
    },
    toOptions() {
      const { allOptions, fromValue } = this

      if (fromValue) {
        const index = allOptions.findIndex(option => option.value === fromValue)

        return allOptions.slice(index + 1)
      }

      return allOptions
    },
    props() {
      return { ...elementUIDefaultProps, ...this.$attrs }
    }
  },
  methods: {
    getValueFromTime(time) {
      return mutateTimeValue(time)
    },
    getTimeFromValue(value) {
      if (!value) {
        return null
      }
      const time = new Date()
      const [hours, minutes] = value.split(":")

      time.setHours(hours, minutes, 0, 0)

      return time
    },
    getTimeFromValues({ from, to }) {
      const { getTimeFromValue } = this

      return {
        from: getTimeFromValue(from),
        to: getTimeFromValue(to)
      }
    },
    onChange({ from, to }) {
      const { getTimeFromValues, getTimeFromValue } = this
      const { isRange } = this.props

      if (isRange) {
        const nextValue = {}

        nextValue.from = from || this.fromValue
        nextValue.to = to || this.toValue

        this.$emit("input", getTimeFromValues(nextValue))
      } else {
        this.$emit("input", getTimeFromValue(from))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep  .el-select {
  width: 100%;
}

.time-stepper {
  display: flex;
  line-height: 1.6;

  .time-stepper-select {
    width: 45%;
    &:first-child {
      margin-right: auto;
    }
  }
}
</style>
